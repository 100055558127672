import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/galidog-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sentry"
    }}>{`Sentry`}</h1>
    <p>{`L'application mobile Galidog intègre la solution `}<a parentName="p" {...{
        "href": "https://sentry.io/welcome/"
      }}>{`Sentry`}</a>{` afin de collecter les erreurs en temps réel remontées depuis l'application.`}</p>
    <blockquote>
      <p parentName="blockquote">{`La console Sentry du projet est accessible ici : `}<a parentName="p" {...{
          "href": "https://sentry.io/organizations/galidog/issues/?project=5206315"
        }}>{`https://sentry.io/organizations/galidog/issues/?project=5206315`}</a>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      